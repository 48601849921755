.accordion-button {
    /*background-color: rgba(128, 128, 128, 0.5) !important;*/
    font-size: medium;
    padding: 0.8em;
  }
  
  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    color: #000000;
  }

