.usersTasksButton {
  z-index: 999;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: none;
  padding: 5px;
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
}

.usersTasksActive {
  fill: white;
  color: white;
  background-color: rgb(255, 0, 242);
}

@keyframes borderPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}