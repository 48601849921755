/*
* My-accordion-color for InspectorTasks.js
*/
.my-accordion-header-primary .accordion-button:not(.collapsed) {
    background-color: rgba(0, 123, 255, 1) !important;
    color: #fff;
}

.my-accordion-header-primary .accordion-button.collapsed {
    background-color: rgba(0, 123, 255, 0.2) !important;
    color: #000;    
}

.my-accordion-header-secondary .accordion-button:not(.collapsed) {
    background-color: rgba(108, 117, 125, 1) !important;
    color: #fff;
}

.my-accordion-header-secondary .accordion-button.collapsed {
    background-color: rgba(108, 117, 125, 0.2) !important;
    color: #000;    
}

.my-accordion-header-success .accordion-button:not(.collapsed) {
    background-color: rgba(40, 167, 69, 1) !important;
    color: #fff;
}

.my-accordion-header-success .accordion-button.collapsed {
    background-color: rgba(40, 167, 69, 0.2) !important;
    color: #000;    
}

.my-accordion-header-danger .accordion-button:not(.collapsed) {
    background-color: rgba(220, 53, 69, 1) !important;
    color: #fff;
}

.my-accordion-header-danger .accordion-button.collapsed {
    background-color: rgba(220, 53, 69, 0.2) !important;
    color: #000;    
}

.my-accordion-header-warning .accordion-button:not(.collapsed) {
    background-color: rgba(255, 193, 7, 1) !important;
    color: #000;
}

.my-accordion-header-warning .accordion-button.collapsed {
    background-color: rgba(255, 193, 7, 0.2) !important;
    color: #000;    
}

.my-accordion-header-info .accordion-button:not(.collapsed) {
    background-color: rgba(23, 162, 184, 1) !important;
    color: #fff;
}

.my-accordion-header-info .accordion-button.collapsed {
    background-color: rgba(23, 162, 184, 0.2) !important;
    color: #000;    
}

.my-accordion-header-light .accordion-button:not(.collapsed) {
    background-color: rgba(248, 249, 250, 1) !important;
    color: #000;
}

.my-accordion-header-light .accordion-button.collapsed {
    background-color: rgba(248, 249, 250, 0.2) !important;
    color: #000;    
}

.my-accordion-header-dark .accordion-button:not(.collapsed) {
    background-color: rgba(52, 58, 64, 1) !important;
    color: #fff;
}

.my-accordion-header-dark .accordion-button.collapsed {
    background-color: rgba(52, 58, 64, 0.2) !important;
    color: #000;    
}


/*
Primary: #007bff
Secondary: #6c757d
Success: #28a745
Danger: #dc3545
Warning: #ffc107
Info: #17a2b8
Light: #f8f9fa
Dark: #343a40

Primary: rgba(0, 123, 255, 1)
Secondary: rgba(108, 117, 125, 1)
Success: rgba(40, 167, 69, 1)
Danger: rgba(220, 53, 69, 1)
Warning: rgba(255, 193, 7, 1)
Info: rgba(23, 162, 184, 1)
Light: rgba(248, 249, 250, 1)
Dark: rgba(52, 58, 64, 1)
*/