label {
  display: block;
  margin-top: 10px;
}

.dropdown-profile {
  position: absolute;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate(0px, -34px);
}

/*
 * Principal style
 */
body {
  font-size: .875rem;
}

.colorLogo {
  color: #f9cf35
}

.feather {
  width: 16px;
  height: 16px;
}

/*
* Login page 
*/ 
.login-container.login {
  max-width: 350px !important;
  padding: 40px 40px;
}

.login {
  background-color:  #f9cf35;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.login-img {
  width: 100%
}

.login-label {
  display: block;
  margin-top: 10px;
}


/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 48px 0 0;
  /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  height: calc(100vh - 48px);
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #2470dc;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
}

/*
 * Navbar
 */
.bg-brand {
  background-color: #f9cf35 !important;
}

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: #f9cf35;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
}

/*
 * Nav-controller
 */
.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/*
 * Mobile Navbar
 */
 .mob-navbar-bg {
  background-color: #f9cf35 !important;
}

 .mob-bg-brand {
  background-color: #f9cf35 !important;
}

.mob-navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: #f9cf35;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.mob-navbar .mob-navbar-toggler {
  background-color: #f9cf35 !important;
  top: .25rem;
  right: 1rem;
}

.mob-navbar .form-control {
  padding: .75rem 1rem;
}

/*
* Leaflet map (don't show rectangle when feature get focus on click)
*/
path.leaflet-interactive:focus {
    outline: none;
}

/*
* Hidden litle  typeAhead X close
*/
.rbt-close-content {
  display: none; /* Oculta cualquier duplicado de la "X" en react-bootstrap-typeahead */
}
