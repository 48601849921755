.userLocationButton {
  z-index: 999;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: none;
  padding: 5px;
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
}

.userLocationActive {
  fill: white;
  color: white;
  background-color: rgb(255, 0, 242);
}

/* CSS para la animación del pulso */
@keyframes borderPulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 0.1;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}

/* Aplicar la animación al círculo */
.locatedAnimation {
  animation: borderPulse 2s infinite;
}
